import React from "react";
import projectsjsx from "./projectsjson";
import "../styles/projects.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

function Projects (){
  return (
    <>
      <div class="container" id="projects">
          <>
            {projectsjsx.map((projectItem)=>(
              <>
              <div class="row animate__fadeInUp">
              <div class="img_container">
                <img class="image_project" src={projectItem.image} alt={projectItem.alt}/>
              </div>
              
              <h1 class="project_name">{projectItem.name}</h1>
              <p class="project_about">{projectItem.about}</p>
              <a class="site_button" href={projectItem.site} target="_blank">site</a>
              </div>

        
              </>
              ))

            }
          </>

        </div>
 
    </>
  )
}
export default Projects;