import React from "react";
import techjs from "./techsjson";
import "../styles/techstack.css";

function TechStacks (){
  return (
    <>
      <div class="container_tech">
      <div class="row_tech">
      <>
      {techjs.map((tech)=>(
        <>
     
        <img class="image_tech" src={tech.image} alt={tech.name}/>
        
        </>
      ))}
      
      </>
      </div>
        
      </div>
    </>
  ) 
}
export default TechStacks;