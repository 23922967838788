const tacos=require('../assets/taco.jpg');
const hotel=require('../assets/hotel.jpg');
const coffee=require('../assets/coffee.jpg');
const music=require('../assets/music.jpg');
const graciousloveministry=require("../assets/graciousloveministry.jpg");
const bikeapp=require("../assets/bike.jpg");
const santa=require("../assets/santa.jpg")
const texas_capitol=require("../assets/texas_.jpg");

const projectsjsx=[
  {
    "id":1,
    "name":"QAT Site",
    "alt":"quality assurance team site",
    "image": texas_capitol,
    "title":"QAT Site",
    "about":"vanilla js",
    "site":"https://qat.dir.texas.gov/"
    // franky-magana-B3wW6GyGOLs-unsplash
  },
    {
      "id":2,
      "name":"tacos",
      "alt":"tacos project",
      "image":tacos,
      "title":"Taco",
      "about":"landing page for taco restaurant",
      "site": "https://647508cbbf49b749e43a6e42--magical-caramel-1ea713.netlify.app/"
      // gonzalo-mendiola-dzn37nOmki4-unsplash (1)
      // jarritos-mexican-soda-E0txIcqePac-unsplash
    },
    // {
    //   "id":2,
    //   "name":"hotel caba",
    //   "alt":"hotel",
    //   "image":hotel,
    //   "title":"Hotel Caba",
    //   "about":"landing page for hotel",
    //   "site":"https://gentle-salamander-66bf8e.netlify.app/"
    //   // night3_jorg-angeli-1tyuLfDOnG0-unsplash
    // },
    {
      "id":3,
      "name":"coffee",
      "alt":"coffee image",
      "image": coffee,
      "title":"CafeBar",
      "about":"landing page for coffee company",
      "site":"https://64750ab237f6b04b3e80edab--peppy-cocada-5b7b65.netlify.app/"
      // mike-kenneally-zlwDJoKTuA8-unsplash
    },

    {
      "id":4,
      "name":"music soul list",
      "alt":"dj image",
      "image": music,
      "title":"music soul list",
      "about":"react app",
      "site":"https://serene-leavitt-b7eb9c.netlify.app"
      // marcela-laskoski-YrtFlrLo2DQ-unsplash
    },
    {
      "id":5,
      "name":"gracious love ministry",
      "alt":"gracious love ministry logo",
      "image": graciousloveministry,
      "title":"gracious love ministry",
      "about":"react app",
      "site":"https://graciousloveministry.org"
    },
    {
      "id":6,
      "name":"Merry Xmas Coding Camp",
      "alt":"xmas",
      "image":santa,
      "title":"Coding Camp",
      "about":"Coding Classes React App",
      "site":"https://merryxmascodinggames.com"
    }

  ] 

export default projectsjsx;