import React, { Component } from "react";
import "../styles/style.css";

class Main extends Component {
  render() {
    return (
      <>

     <div class="about_me" id="home">
          <p class="about_jenn">Hello, I'm <span id="faded">Jenn</span> I'm a 
          <span id="title"> Frontend Software Developer</span>, <br/><span id="title">AWS Solutions Architect</span>, <br/> and <a class="aws_certificate" href="https://www.credly.com/badges/b929618c-f4db-4441-8ff2-549d8900d51d/public_url" target="blank_"><span id="title">Cloud Specialist</span></a>. </p>
     </div>
        
      </>
    );
  }
}

export default Main;
