import React from "react";
import footer from "../styles/footer.css";
import { useForm, ValidationError } from "@formspree/react";
import "../styles/footer.css";

function Footer(){
return(
<>
  <div class="container_footer">
        <div class="row_footer">
        <div>
        <p class="footer_notes">If you would like to connect please email me at jenniferperezcodes@gmail.com</p>
        <p class="footer_notes">Thanks for visiting!</p>
        <p class="footer_notes">©Website by Jenn Perez</p>
      </div>
    </div>
  </div>
</>
)

}
export default Footer;