import React from "react";
import "../styles/about.css";

function About(){
return(
  <>
   <div class="container">  
 <p class="about_content"> 
 Jennifer Perez holds a degree in Computer Information Systems from Baruch College, where she acquired a strong foundation in Project Management, including systems design and UML diagrams. 
 Post-graduation, she pursued further education by enrolling in coding programs, 
 where she gained proficiency in developing full-stack websites and received AWS cloud training. 
 Over the course of her tech career, Jennifer has successfully designed and developed full-stack websites 
 as well as a three-tier cloud application. She is currently actively seeking opportunities to 
 apply and expand her skills in a professional tech environment. Please do not hesitate to get in touch 
 should you have any inquiries, feedback, or potential opportunities.
 Thank you for visiting! </p>
 </div>
 </>
)
}
export default About;