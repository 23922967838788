import React, { Component } from 'react';
import { BrowserRouter as Router,Routes, Route, Link } from 'react-router-dom';
import Navbar from "./component/navbar";
import Main from "./component/main";
import About from "./component/about";
import Footer from "./component/footer";
import Projects from "./component/projects";
import TechStacks from "./component/stacks";
import AWS from "./component/aws";

class App extends Component {
  render() {
    return (
<>  
<Navbar/>
<Main/>
<About/>
<Projects/>
<AWS/>
<TechStacks/>
<Footer/>


</>
   );
  }
}
export default App;
