import React from "react";
import "../styles/style.css";

function Navbar(){
  return(
    <>

      <div class="navbar">
           <ul class="nav-items">
           <a href="#home"> <li>Home</li></a>
           <a href="#projects"> <li>Projects</li> </a>      
           <a href="#tech"><li>Tech</li></a>
            </ul>
      </div>
 
  
    

    
      </>
  )
}
export default Navbar;