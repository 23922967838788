import React from "react";
import "../styles/certs.css";

const cloud=require("../assets/certs/AWS-Certified-Cloud-Practitioner_.png");
const solutionsarchitect=require("../assets/certs/AWS-Certified_Solutions-Architect_Associate_.png");

function AWS (){
  return(<>
  <div class="certs" id="tech">
    <div class="row_certs">
    <h3>Certifications</h3>
    </div>
 <div class="certs_">
   <a class="aws_certificate" href="https://www.credly.com/badges/b929618c-f4db-4441-8ff2-549d8900d51d/public_url" target="blank_">
   <img class="certificate" src={cloud} alt="cloud png "/></a>
   <img class="certificate" src={solutionsarchitect} alt="solutions architect png"/>
 </div>

 
  </div>

    
</>)
}
export default AWS;