const bootstrap=require("../assets/techstacks/bootstrap.png");
const css=require("../assets/techstacks/css-3.png");
const sql=require("../assets/techstacks/database.png");
const github=require("../assets/techstacks/github-sign.png");
const html5=require("../assets/techstacks/html-5.png");
const js=require("../assets/techstacks/js.png");
const nodejs=require("../assets/techstacks/node-js.png");
const postgres=require("../assets/techstacks/postgre.png");
const postman=require("../assets/techstacks/postman.png");
const react=require("../assets/techstacks/react.png");
const salesforce=require("../assets/techstacks/salesforce.png");
const sass=require("../assets/techstacks/sass.png");
const figma=require("../assets/techstacks/figma.png");

const techjs=[
  {
    "name":"bootstrap",
    "image":bootstrap
  },
  {
    "name":"css3",
    "image":css,
  },
  {
    "name":"sql",
    "image":sql
  },
  {
    "name":"github",
    "image":github
  },
  {
    "name":"html5",
    "image":html5
  },
  {
    "name":"js",
    "image":js
  },
  {
    "name":"nodejs",
    "image":nodejs
  },
 
  {
    "name":"postgres",
    "image":postgres
  },  
  {
    "name":"postman",
    "image":postman
  },
    
  {
    "name":"react",
    "image":react
  },
  {
    "name":"salesforce",
    "image":salesforce
  },
  {
    "name":"sass",
    "image":sass
  },
  {
    "name":"figma",
    "image":figma
  }
]
export default techjs;
